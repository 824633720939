<template>
  <div class="triage-tool">
    <div v-if="triageStore.hasPrevQuestion" class="triage-tool__back">
      <button class="button-link is-secondary is-strong" @click.prevent="prevQuestion">
        <i>
          <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9.18494 15.7601C9.59193 15.3711 9.60651 14.7258 9.21751 14.3188L3.8974 8.75272L9.21751 3.18663C9.60651 2.77964 9.59193 2.13437 9.18494 1.74536C8.77795 1.35636 8.13268 1.37094 7.74367 1.77793L1.76245 8.0357C1.57062 8.2364 1.47686 8.49606 1.48006 8.75276C1.47688 9.00942 1.57064 9.26905 1.76246 9.46974L7.74367 15.7275C8.13268 16.1345 8.77795 16.1491 9.18494 15.7601Z"
              fill="#DC291E"
              stroke="#DC291E"
            />
          </svg>
        </i>
        <span>Go Back</span>
      </button>
    </div>
    <header class="triage-tool__quiz-header">
      <div class="triage-tool__quiz-steps">{{ currentStep }} of {{ maxSteps }}</div>
      <span
        v-for="index in maxSteps"
        :key="index"
        class="triage-tool__quiz-step"
        :class="{ 'is-current': currentStep == index, 'is-past': currentStep > index }"
      >
        <span>{{ index }}</span>
      </span>
    </header>
    <div v-if="triageStore.hasResult" class="triage-tool__results">
      <triage-tool-result @close="emit('close')" @reset="emit('reset')" />
    </div>
    <div v-else class="triage-tool__quiz">
      <div class="triage-tool__quiz-slide">
        <triage-tool-slide @close="emit('close')" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import TriageToolSlide from '@/components/global/components/triage-tool/TriageToolSlide.vue';
import TriageToolResult from '@/components/global/components/triage-tool/TriageToolResult.vue';
import { useTriageToolStore } from '~/stores/triage-tool';

const emit = defineEmits(['close', 'reset']);

const MIN_STEPS = 4;

const triageStore = useTriageToolStore();

const currentStep = computed(() => {
  const resultStep = triageStore.hasResult ? 1 : 0;
  return triageStore.questionsPath.length + resultStep;
});

const minSteps = computed(() => (triageStore.hasResult ? 0 : MIN_STEPS));
const maxSteps = computed(() => Math.max(minSteps.value, currentStep.value) + (triageStore.hasResult ? 0 : 1));

const prevQuestion = () => triageStore.prevQuestion();
</script>
