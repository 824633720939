<template>
  <div class="buttons" :class="rootClasses">
    <slot />
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  spacing: {
    type: String,
    default: '',
    validator: (value) => ['', 'wide', 'wider', 'none'].includes(value),
  },
  wrap: {
    type: Boolean,
    default: false,
  },
  centered: {
    type: Boolean,
    default: false,
  },
  tinyFull: {
    type: Boolean,
    default: true,
  },
});

const rootClasses = computed(() => {
  const classes = [];

  if (props.spacing) {
    classes.push(`has-sp-${props.spacing}`);
  }
  if (props.wrap) {
    classes.push('has-wrap');
  }
  if (props.centered) {
    classes.push('is-centered');
  }
  if (props.tinyFull) {
    classes.push('is-tiny-full');
  }
  return classes;
});
</script>
