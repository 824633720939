<template>
  <div class="card" :class="rootClasses">
    <div class="card__inner">
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  rounded: {
    type: Boolean,
    default: true,
  },

  hasPad: {
    type: String,
    default: '',
    validator: (value) => ['l', 's', 'xs', 'xsmall', 'small', 'large', ''].includes(value),
  },

  shadow: Boolean,
  fullheight: Boolean,

  border: {
    type: String,
    default: '',
    validator: (value) => ['dark', 'grey', ''].includes(value),
  },

  bg: {
    type: String,
    default: '',
    validator: (value) => ['', 'dark', 'primary'].includes(value),
  },
});

const rootClasses = computed(() => {
  const classes = [];
  if (props.hasPad) {
    classes.push(`has-pad-${props.hasPad}`);
  }
  if (props.shadow) {
    classes.push('has-shadow');
  }
  if (props.rounded) {
    classes.push('is-rounded');
  }
  if (props.border) {
    classes.push(`has-border-${props.border}`);
  }
  if (props.bg) {
    classes.push(`has-bg-${props.bg}`);
  }
  if (props.fullheight) {
    classes.push('is-fullheight');
  }

  return classes;
});
</script>
