<template>
  <pt-card fullheight border="dark" has-pad="small" class="digital-screen-triage-tool-pricing-card">
    <div class="digital-screen-triage-tool-pricing-card__image">
      <picture-basic v-if="item.image" v-bind="imageBindings" />
    </div>
    <div class="digital-screen-triage-tool-pricing-card__body">
      <div class="digital-screen-triage-tool-pricing-card__body-title">
        {{ item.title }}
      </div>
      <div class="digital-screen-triage-tool-pricing-card__body-price">
        {{ pricingHeader }}
      </div>
      <div class="digital-screen-triage-tool-pricing-card__body-subtitle">
        {{ item.subtitle }}
      </div>
    </div>
    <pt-button tag="app-link" :to="getPricingItemLink(item)" outlined wider class="digital-screen-triage-tool-pricing-card__cta">
      Read more
    </pt-button>
  </pt-card>
</template>

<script lang="ts" setup>
import { useDigitalScreen } from '~/composables/digital-screen';
import { useImages } from '~/composables/images';
import PtButton from '~/components/global/elements/PtButton.vue';
import PictureBasic from '~/components/global/images/PictureBasic.vue';
import PtCard from '~/components/global/elements/PtCard.vue';
import type { LooseObject } from '~/types/generic';

const props = defineProps({
  item: {
    type: Object as () => LooseObject,
    required: true,
  },
});

const { getPictureBindings } = useImages();
const { getPricingItemLink } = useDigitalScreen();

const imageBindings = computed(() => getPictureBindings(props.item.image, { ratio: null, contain: false, heightContained: true }));

const pricingHeader = computed(() => {
  if (props.item.has_couple_price && props.item.has_single_price) {
    return `from ${props.item.single_price}`;
  } else if (props.item.has_couple_price) {
    return props.item.couple_price;
  }
  return props.item.single_price;
});
</script>
