import { withTrailingSlash } from 'ufo';
import { useDigitalScreenStore } from '~/stores/digital-screen';

export const useDigitalScreen = () => {
  const getPricingItemLink = (pricingItem: any) => {
    return withTrailingSlash(`/digital-screen/pricing-item/${pricingItem.slug}`);
  };

  const isDigitalScreenPath = (path: string) => path.includes('digital-screen');

  const digitalScreenStore = useDigitalScreenStore();
  const currentDigitalScreenPage = computed(() => digitalScreenStore.currentDigitalScreenPage);
  const digitalScreenMode = computed(() => digitalScreenStore.digitalScreenMode);

  return {
    getPricingItemLink,
    isDigitalScreenPath,
    currentDigitalScreenPage,
    digitalScreenMode,
  };
};
