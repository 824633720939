import { defineStore } from 'pinia';

export const useFeaturedVideoStore = defineStore('featuredVideo', {
  state: () => ({
    currentVideo: null as any,
  }),
  actions: {
    setCurrentVideo(currentVideo: any) {
      this.currentVideo = currentVideo;
    },
  },
});
