<template>
  <div class="pricing-card-header box is-rounded no-pad" :class="rootClasses">
    <div class="pricing-card-header__image-wrapper">
      <picture-basic v-if="item.image" v-bind="imageBindings" class="pricing-card-header__image" />
    </div>
    <div v-if="withContent" class="pricing-card-header__content has-pad-right-xs has-pad-top-m has-pad-bottom-xxs">
      <h3 class="title is-4 is-strong">
        {{ item.title }}
      </h3>
      <div class="pricing-card-header__content-price title is-1 is-strong has-marg-top-xxs">
        <template v-if="item.has_single_price">
          {{ item.single_price }}
        </template>
        <template v-else>
          {{ item.couple_price }}
        </template>
      </div>
      <div class="pricing-card-header__content-description has-marg-top-xxs">
        {{ item.subtitle }}
      </div>
      <div v-if="buttonBindings" class="pricing-card-header__button-wrapper has-pad-top-m has-pad-bottom-s">
        <pt-button v-bind="buttonBindings">
          {{ item.secondary_button_label }}
        </pt-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useImages } from '~/composables/images';
import PictureBasic from '~/components/global/images/PictureBasic.vue';
import PtButton from '~/components/global/elements/PtButton.vue';
import type { LooseObject } from '~/types/generic';

const props = defineProps({
  item: {
    type: Object as () => LooseObject,
    required: true,
  },
  alwaysOnLeft: Boolean,
  fullheight: Boolean,
  withContent: {
    type: Boolean,
    default: true,
  },
  bg: {
    type: String,
    default: '',
    validator: (value) => ['lightblue-light', 'pink-light', 'peach-light'].includes(value),
  },
  buttonBindings: {
    type: Object,
    default: undefined,
  },
});

const { getPictureBindings } = useImages();

const imageBindings = computed(() => {
  return getPictureBindings(props.item.image, {
    preset: 'pricing',
    ratio: null,
  });
});

const rootClasses = computed(() => {
  const classes = [];

  if (props.fullheight) {
    classes.push('is-fullheight');
  }

  if (props.withContent) {
    classes.push('is-with-content');
  }

  if (props.alwaysOnLeft) {
    classes.push('is-always-on-left');
  }

  if (props.bg) {
    classes.push(`has-bg-${props.bg}`);
  }

  return classes;
});
</script>
