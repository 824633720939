<template>
  <div class="triage-tool-answer">
    <button class="triage-tool-answer__cta" :class="buttonClass" @click.prevent="emit('answer')">
      <div class="triage-tool-answer__title">{{ title }}</div>
      <div v-if="description" class="triage-tool-answer__description">
        <p>{{ description }}</p>
      </div>
    </button>
  </div>
</template>

<script lang="ts" setup>
const emit = defineEmits(['answer']);

defineProps({
  title: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    default: null,
  },
  buttonClass: {
    type: [Object, Array, String],
    default: null,
  },
});
</script>
