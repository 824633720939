<template>
  <div class="triage-tool-result">
    <template v-if="triageStore.hasResultOptions">
      <h3 class="triage-tool-result__title title is-2 is-centered">This is the will package best suited to your circumstances</h3>
      <div class="triage-tool-result__result">
        <div class="triage-tool-result__pricing">
          <div v-for="item in relevantPricing" :key="item.slug" class="triage-tool-result__pricing-item">
            <div v-if="!isDigitalScreenModeEnabled" class="triage-tool-result__pricing-start">
              <pt-button
                v-if="item.slug === 'in-centre-will'"
                tag="app-link"
                to="/locations/"
                type="is-primary"
                class="triage-tool-result__book-cta"
                expanded
                @click="onBookAppointment"
              >
                Book an appointment
              </pt-button>
              <pt-button
                v-else
                tag="a"
                href="https://www.publictrustonline.co.nz/sign-up"
                target="_blank"
                rel="nofollow noopener"
                type="is-primary"
                class="triage-tool-result__online-cta"
                expanded
                @click="onStartOnline"
              >
                Start online now
              </pt-button>
            </div>
            <pricing-card v-if="!isDigitalScreenModeEnabled" :item="item" />
            <digital-screen-triage-tool-pricing-card v-else :item="item" />
          </div>
        </div>
      </div>
    </template>

    <h3 v-else class="triage-tool-result__no-options title is-2 is-centered">
      We can't find the best option for you, give us a call <a class="link-u is-3" :href="contactPhoneHref">0800 371 471</a> and we will see how we
      can help you.
    </h3>

    <div class="triage-tool-result__restart">
      <button class="button-link is-secondary is-strong is-inline-block" @click="restart()">
        <span>Restart</span>
      </button>
      <app-link :to="triageStore.viewAllLink" class="button-link is-secondary is-strong is-inline-block" @click="onAllOptions">
        <span>View all will options</span>
      </app-link>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useGtm } from '@gtm-support/vue-gtm';
import { formatPhoneHref } from '@/utils/formatters';
import { useTriageToolStore } from '~/stores/triage-tool';
import { useGlobalsStore } from '~/stores/globals';
import AppLink from '~/components/AppLink';
import PricingCard from '~/components/global/components/pricing/PricingCard.vue';
import PtButton from '~/components/global/elements/PtButton.vue';
import { useDigitalScreenStore } from '~/stores/digital-screen';
import DigitalScreenTriageToolPricingCard from '~/components/global/components/digital-screen/DigitalScreenTriageToolPricingCard.vue';

const emit = defineEmits(['reset', 'close']);

const gtm = useGtm();

const triageStore = useTriageToolStore();
const globalsStore = useGlobalsStore();
const digitalScreenStore = useDigitalScreenStore();

const isDigitalScreenModeEnabled = computed(() => digitalScreenStore.isDigitalScreenModeEnabled);

const relevantPricing = computed(() => triageStore.willsPricingList.filter((p) => triageStore.result?.includes(p.slug)));

const contactPhoneHref = computed(() => formatPhoneHref(globalsStore.contactPhone));

const restart = () => {
  gtm.push({ event: 'triage:restart' });
  triageStore.reset();
  emit('reset');
};

const onBookAppointment = () => {
  gtm.push({
    event: 'triage:result',
    answer: 'appointment',
  });
  emit('close');
};

const onStartOnline = () => {
  gtm.push({
    event: 'triage:result',
    answer: 'online',
  });
};

const onAllOptions = () => {
  gtm.push({ event: 'triage:all-options' });
  emit('close');
};
</script>
