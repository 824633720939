<template>
  <pt-card fullheight border="grey" has-pad="xs">
    <pricing-card-header :item="item" with-content :bg="headerBg" />
    <div class="has-marg-top-m has-marg-bottom-m">
      <bard v-if="item.description" :as-content="true" :content="item.description" />
      <bard v-if="item.description_column_1" :as-content="true" :content="item.description_column_1" />
      <bard v-if="item.description_column_2" :as-content="true" :content="item.description_column_2" />
      <bard v-if="item.description_column_3" :as-content="true" :content="item.description_column_3" />
    </div>
  </pt-card>
</template>

<script setup lang="ts">
import { getSelectValue } from '@/utils/blocks';
import Bard from '~/components/content/Bard.vue';
import PtCard from '~/components/global/elements/PtCard.vue';
import PricingCardHeader from '~/components/global/components/pricing/PricingCardHeader.vue';

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const headerBg = computed(() => `${getSelectValue(props.item.colour) || 'pink'}-light`);
</script>
