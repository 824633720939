<template>
  <div v-if="currentQuestion" class="triage-tool-slide">
    <h3 class="triage-tool-slide__title title is-2 is-centered">
      {{ currentQuestion.title }}
    </h3>
    <p class="triage-tool-slide__desc">
      {{ currentQuestion.description }}
    </p>
    <ul class="triage-tool-slide__answers">
      <li v-for="(answer, i) in currentQuestion.answers" :key="`${currentQuestion.id}-${i}`" class="triage-tool-slide__answer">
        <triage-tool-answer
          :title="answer.title"
          :description="answer.description"
          :button-class="getAnswerId(answer)"
          @answer="handleAnswer({ result: answer.result, nextQuestion: answer.next, answerId: getAnswerId(answer) })"
        />
      </li>
    </ul>
    <div v-if="currentQuestion.id === 0" class="triage-tool-slide__footer">
      <p>
        Not sure if you fit into the above options?<br />
        Call <a class="link-u is-3 triage-tool-slide__contact-phone" :href="contactPhoneHref"><span>0800 371 471</span></a>
        and we will see how we can help you.
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useGtm } from '@gtm-support/vue-gtm';
import { formatPhoneHref } from '@/utils/formatters';
import { useTriageToolStore } from '~/stores/triage-tool';
import { useGlobalsStore } from '~/stores/globals';
import TriageToolAnswer from '~/components/global/components/triage-tool/TriageToolAnswer.vue';

const gtm = useGtm();

const triageStore = useTriageToolStore();
const globalsStore = useGlobalsStore();

const contactPhoneHref = computed(() => formatPhoneHref(globalsStore.contactPhone));
const currentQuestion = computed(() => triageStore.currentQuestion);

const getAnswerId = computed(() => (answer) => `q${currentQuestion.value.id}${answer.title.toLowerCase()}`);

const handleAnswer = ({ result = null, nextQuestion = null, answerId }) => {
  if (gtm) {
    gtm.push({
      event: 'triage:answer',
      answer: answerId,
    });
  }

  if (result !== null) {
    triageStore.setResult(result);
  }
  if (nextQuestion !== null) {
    triageStore.nextQuestion(nextQuestion);
  }
};
</script>
